import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Colors from '../../components/Colors';
import SvgDownArrow from '../../images/icons/downArrow.inline.svg';
import useElementOnScreen from '../../utils/useElementOnScreen';
import DiscoverLink from '../buttons/DiscoverLink';

type IntroductionProps = {
  linkTo: string;
};

const Introduction: React.VoidFunctionComponent<IntroductionProps> = ({ linkTo }) => {
  const { t } = useTranslation('academy');
  const [animatedElement, isInView] = useElementOnScreen<HTMLDivElement>();

  return (
    <div className="fixed flex h-screen w-full">
      <header
        className={`relative flex h-screen w-full snap-start flex-wrap justify-center ${Colors.WHITE_ON_BLUE}`}
      >
        <div
          className={`max-w-4xl translate-y-12 self-center px-4 opacity-0 animate-delay-75 sm:px-8 ${
            isInView ? 'animate-slidein' : ''
          }`}
          ref={animatedElement}
        >
          <h1>{t('introduction.title')}</h1>
          <p className="mt-4 whitespace-pre-line text-center sm:mt-8">
            {t('introduction.description1')}
          </p>
          <p className="mt-4 text-center sm:mt-8">{t('introduction.description2')}</p>
          <div className="mt-4 text-center sm:mt-8">
            <DiscoverLink
              text={t('introduction.download-catalog')}
              href="https://tally.so/r/3NBpjb"
              pseudoClass={Colors.WHITE_ON_BLUE_PSEUDO_CLASS}
              svgClass="rotate-90"
            />
          </div>
        </div>
        <a
          className="absolute bottom-0 animate-bounce text-center sm:bottom-12"
          title={t('introduction.discover')}
          href={linkTo}
          rel="nofollow"
        >
          <SvgDownArrow className="mx-auto block" />
          <p className="mt-3 font-medium">{t('introduction.discover')}</p>
        </a>
      </header>
    </div>
  );
};

export default Introduction;
